const deeprootsSettings = {
	title: "DeepRoots Network",
	description: "A news service focused on indigenous communities and ancient wisdom. It covers news on cultural preservation, natural medicine, land rights, and environmental stewardship from the perspectives of underrepresented native populations worldwide.",
	logo: "/logo.png",
	logoBig: "/logo512.png",
	headerBg: 'rgb(24,82,31)',
	footerBg: "rgb(24,82,31)",
	headerTextColor: "#fff",
	footerTextColor: "#fff",
	style: 'narrative'
};

export default deeprootsSettings;
